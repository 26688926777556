exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-template-blog-details-js": () => import("./../../../src/template/blog-details.js" /* webpackChunkName: "component---src-template-blog-details-js" */),
  "component---src-template-product-js": () => import("./../../../src/template/product.js" /* webpackChunkName: "component---src-template-product-js" */),
  "component---src-template-project-details-js": () => import("./../../../src/template/project-details.js" /* webpackChunkName: "component---src-template-project-details-js" */)
}

